<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: "Documentos",
    data() {
        return {
            pageTitle: this.name
        }
    },
});
</script>

<template>
  <router-view />
</template>

